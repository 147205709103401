import '../styles/style.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { apiUrl, isLoggedIn, userName, passCode, loadingActive, modalMessage, modalVisible, 
    modalType, businessEntityKey, pageSelected
} from '../atoms';

export default function Login() {
    // Read only variables
    const url = useRecoilValue(apiUrl);

    // Two way binding
    const [user, setUser] = useRecoilState(userName);
    const [password, setPassword] = useRecoilState(passCode);

    // Sets variable values
    const setLoading = useSetRecoilState(loadingActive);
    const setModalMessage = useSetRecoilState(modalMessage);
    const setModalVisible = useSetRecoilState(modalVisible);
    const setModalType = useSetRecoilState(modalType);
    const setBusinessKey = useSetRecoilState(businessEntityKey);
    const setPage = useSetRecoilState(pageSelected);
    const setLoggedIn = useSetRecoilState(isLoggedIn);

    const UpdateUser = (event) => {
        setUser(event.target.value);
    }

    const UpdatePassword = (event) => {
        setPassword(event.target.value);
    }

    const UserLoggedIn = (data) => {
        setLoggedIn(true);
        setBusinessKey(data.user.roleAssignments[0].businessUnitEntityKey);
        setPage("search");
    }

    const showError = (message) => {
        setLoading(false); // hide loading animation
        setModalMessage(message);
        setModalVisible(true);
        setModalType("error");
    }

    const SubmitLogin = () => {
        setLoading(true); // Show loading animation

        if (user === "") {
            showError("An empty username is not permitted!");
        }
        else if ( password === "") {
            showError("An empty password is not permitted!");
        }
        else {
            var jsonBody = {};
            jsonBody.username = user;
            jsonBody.password = password;

            fetch(url + "Login",
                {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "content-type": "application/json",
                        "Accept": "application/json"
                      },
                    body: JSON.stringify(jsonBody),
                }).then((response) => response.json())
                .then((data) => {
                    setLoading(false); // hide loading animation

                    if (data && data.customer !== null) {
                        UserLoggedIn(data); // Update variables
                    }
                    else {
                        showError("User was not authenticated. Please check your username/password combination.");
                    }
                })
                .catch((error) => {
                    showError(error);
                });
        }
    }

    return (
            <div className="modal-block-back-short">
                <div className="modal-header modal-header-large modal-header-regular"><div className="v-float h-float">Login</div></div>
                <div className="modal-body">
                    <div className="modal-row">
                        <div className="login-row-icon v-float"><FontAwesomeIcon icon={faUser} /></div>
                        <div className="login-row-input">
                            <div><label htmlFor="username">Username</label></div>
                            <div><input type="email" id="username" onChange={UpdateUser} value={user} /></div>
                        </div>
                    </div>
                    <div className="modal-row">
                        <div className="login-row-icon v-float"><FontAwesomeIcon icon={faLock} /></div>
                        <div className="login-row-input">
                            <div><label htmlFor="password">Password</label></div>
                            <div><input type="password" id="password" onChange={UpdatePassword} value={password} /></div>
                        </div>
                    </div>
                    <div className="modal-row">
                        <div className="modal-row-button modal-row-button-regular width-100">
                            <button onClick={SubmitLogin}>Login</button>
                        </div>
                    </div>
                </div>
            </div>
    );
}
